import React from "react"

const ThesisLogo = ({ width, height }) => {
  return (
    <svg
      alt="Thesis"
      viewBox="0 0 112 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M108.296 4.60012H112V7.44781H108.296L110.257 10.7336L107.86 12.0479L106.117 8.76213L104.374 12.0479L101.977 10.7336L103.938 7.44781H100.233V4.60012H103.938L101.977 1.31432L104.156 0L105.899 3.2858L107.86 0L110.257 1.31432L108.296 4.60012Z"
        fill="white"
      />
      <path
        d="M92.607 26.9438C88.0311 26.9438 84.5448 24.5342 83.8911 20.5912H88.6848C89.3385 22.1246 91.2996 22.7818 92.607 22.7818C94.5681 22.7818 95.8755 22.1246 95.8755 21.2484C95.8755 20.1531 95.2218 19.715 91.9533 19.2769C88.6848 18.8388 84.3269 17.9626 84.3269 13.8006C84.3269 10.9529 86.9416 7.88616 92.1712 7.88616C96.3113 7.88616 99.3619 10.0767 100.016 13.5815H95.2218C95.0039 12.7053 93.9144 12.0482 92.1712 12.0482C89.9922 12.0482 89.1206 12.9244 89.1206 13.5815C89.1206 14.4578 90.428 14.8959 92.8249 15.1149C97.4008 15.7721 100.669 16.8673 100.669 20.8103C100.669 24.3152 97.4008 26.9438 92.607 26.9438Z"
        fill="white"
      />
      <path
        d="M82.5836 8.10514H78.0078V26.5056H82.5836V8.10514Z"
        fill="white"
      />
      <path
        d="M68.2023 26.9438C63.6264 26.9438 60.1401 24.5342 59.4864 20.5912H64.2801C64.9338 22.1246 66.8949 22.7818 68.2023 22.7818C70.1634 22.7818 71.4708 22.1246 71.4708 21.2484C71.4708 20.1531 70.8171 19.715 67.5486 19.2769C64.2802 18.8388 59.9222 17.9626 59.9222 13.8006C59.9222 10.9529 62.537 7.88616 67.7665 7.88616C71.9066 7.88616 74.9572 10.0767 75.6109 13.5815H70.8171C70.5992 12.7053 69.5097 12.0482 67.7665 12.0482C65.5875 12.0482 64.7159 12.9244 64.7159 13.5815C64.7159 14.4578 66.0233 14.8959 68.4202 15.1149C72.9961 15.7721 76.2646 16.6483 76.2646 20.8103C76.2646 24.3152 72.9961 26.9438 68.2023 26.9438Z"
        fill="white"
      />
      <path
        d="M49.0272 26.9435C43.7976 26.5054 40.0933 21.9053 40.5291 16.648C40.9649 11.3908 45.5408 7.44782 50.5525 7.66687C54.9105 8.10498 58.3969 11.1717 58.8327 15.9909L45.5408 20.372C46.4124 21.6863 47.7198 22.5625 49.2451 22.5625C51.4241 22.7815 53.1673 21.4672 53.3852 20.372H58.3969C58.3969 22.3434 55.5642 27.6007 49.0272 26.9435ZM53.6031 13.8004C52.7315 12.7051 51.642 12.0479 50.1167 11.8289C47.5019 11.6098 45.105 13.5813 44.6692 16.648L53.6031 13.8004Z"
        fill="white"
      />
      <path
        d="M39.0039 26.5054H34.428V15.7718C34.428 14.6766 33.9922 12.267 30.9416 12.267C28.7626 12.267 27.4553 13.5813 27.4553 15.7718V26.5054H22.6615V0H27.4553V9.20023C28.5447 8.32402 30.07 7.88591 31.5953 7.88591C35.9533 7.88591 39.0039 11.1717 39.0039 15.9909V26.5054Z"
        fill="white"
      />
      <path
        d="M15.9066 17.3052V19.0576C15.9066 20.81 14.8171 22.3434 12.6381 22.3434C10.2412 22.3434 9.36965 20.591 9.36965 19.0576V12.486H20.4825V8.10494H9.36965V3.50482H4.57587V8.10494H0V12.486H4.57587V18.8385C4.57587 23.4387 7.62646 26.9435 12.6381 26.9435C17.4319 26.9435 20.7004 23.4387 20.7004 18.8385V17.3052H15.9066Z"
        fill="white"
      />
    </svg>
  )
}

export default ThesisLogo
